// import { createMuiTheme } from "@material-ui/core"; // todo later, as still a warning needs to be fixed
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core"; // tmp while the while: https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode

/** Cuztomized Material UI (MUI) Theme for PinPower.io
 * https://material-ui.com/customization/theming/
 */
const defaultTheme = createMuiTheme({
  palette: {
    // type: "dark",
    // type: prefersDarkMode ? "dark" : "light",

    primary: {
      main: "#d3af37",
    },
    secondary: {
      main: "#375cd4",
    },
  },
  overrides: {
    MuiButton: {
      // Name of the rule
      containedSecondary: {
        background: "rgb(67,103,215)",
        background:
          "linear-gradient(180deg, rgba(67,103,215,1) 0%, rgba(80,172,220,1) 100%)",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 60,
        // padding: "0 30px",
        // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        boxShadow: 0,
        fontWeight: 900,
      },
      outlinedSecondary: {
        height: 60,
        fontWeight: 900,
      },
    },
    MuiInput: {
      
    }
  },
  // see https://material-ui.com/customization/default-theme/?expand-path=$.typography
  typography: {
    button: {

    },
  },
});

export { defaultTheme };
