import {
  makeStyles,
  Box,
  Container,
} from "@material-ui/core";

import React, { useEffect, useContext, useState } from "react";
import {Context} from './../../GlobalStates';
import { useHistory } from "react-router-dom";

import Particles from "react-tsparticles";
import Data from "./assets/particlesmk.json";

import './styles/Login.css';

import { ReactComponent as LogoFullWhite } from './assets/Logo_Full--White.svg';
import { ReactComponent as LogoMK } from './assets/Logo_MK.svg';

import LoginSnackbar from './components/LoginSnackbar.js';

import LoginContentDialog__Login from './components/LoginContentDialog__Login.js';
import LoginContentDialog__PWReset__SendCode from './components/LoginContentDialog__PWReset__SendCode.js';
import LoginContentDialog__PWReset__ConfirmCode from './components/LoginContentDialog__PWReset__ConfirmCode.js';
import LoginContentDialog__PWReset__ChangePW from './components/LoginContentDialog__PWReset__ChangePW.js';


/** Throws an overlay, if the screensize is less than 1000x500px.
 *
 * @note mobile view is not supported, as it's an agency/work tool.
 * @since 2021.09.17
 */
function Main ({page}) {

  //GlobalStates
  const [globalState, dispatch] = useContext(Context);

  console.log("Main Page");

  useEffect(() => {
    document.title = "WertUp";
    dispatch({type: 'SET_NEW_SNACKBAR', payload: null }); // reset all prior snackbar messages
  }, []); // just called once

  // https://particles.js.org/samples/index.html#
  // https://codesandbox.io/s/react-tsparticles-dw43f
  // https://stackoverflow.com/questions/61054565/particles-js-not-showing-up-in-reactjs-project
  const particlesSettingsObj = {
    particles: {
      color: {
        value: "#000000"
      },
      line_linked: {
        color: {
          value: "#000000"
        }
      },
      number: {
        value: 50
      },
      size: {
        value: 3
      }
    }
  }


  const handleOnClickLoginBtn = () => {
    window.location = "mailto:contact@wertup.com";
  }

  //console.log("AppScreenSizeCheck | Start") globalState.loginDialogArr[0].dialog
  /*if (loggedIn) {
    return (
      <>
        <Box className="LoginFadeOut LoginFadeOut--Animation"/>
      </>
    )
  } else {*/
    return (
      <Box className="Login">
        <Particles
          style={{ position: "absolute" }}
          height="100%"
          width="100%"
          params={Data}
        />
        <Box className="LoginContent">
          <span className="LoginContent__Headline">Wert<span style={{ color: "#f4981c"}}>Up</span></span>
          <Box style={{ marginTop: 25 }}>
            <span style={{ fontSize: "1.5em", color: "white" }}>Big Data Solution Provider</span>
          </Box>

          <Box style={{ marginTop: 25 }}>
            <span style={{ color: "#f4981c", fontSize: "1.5rem", padding: 10, backgroundColor: "#1C2633", borderRadius: 4 }}></span>
            {/*<span style={{ color: "#f4981c", fontSize: "1.5rem", padding: 10}}></span>*/}
          </Box>
          <Box style={{ marginTop: 5 }}>
            <span style={{ color: "#f4981c", fontSize: "1.5rem", padding: 10, backgroundColor: "#1C2633", borderRadius: 4 }}></span>
            {/*<span style={{ color: "#f4981c", fontSize: "1.5rem", padding: 10}}></span>*/}
          </Box>

          { (globalState.loginDialogArr.length == 0)
          ? ( <Box className="LoginContent__LoginBtn" onClick={handleOnClickLoginBtn}>CONTACT</Box>)
          : ( <Box className="LoginContent__Dialogs" style={{ zIndex: 1}}>
                {console.log("globalState.loginDialogArr[0]: " + JSON.stringify(globalState.loginDialogArr[0]))}
                {
                  {
                    'LoginContentDialog__Login' : <LoginContentDialog__Login /> ,
                    'LoginContentDialog__PWReset__SendCode': <LoginContentDialog__PWReset__SendCode />,
                    'LoginContentDialog__PWReset__ConfirmCode': <LoginContentDialog__PWReset__ConfirmCode />,
                    'LoginContentDialog__PWReset__ChangePW': <LoginContentDialog__PWReset__ChangePW />
                  }[globalState.loginDialogArr[0].dialog]
                }
                </Box>
            ) }
        </Box>
        { /* No idea why, but maybe in combination with the snackbars, if I login sometimes the marktplatz-komplizen url loads. Maybe a strict issue. Better don't show link. */ }
        <a className="Impressum__Link" href="https://wertup.com/impressum" style={{ textDecoration: "none"}}>Impressum</a>
        <LoginSnackbar />
      </Box>
    )
  //}

}

export default Main