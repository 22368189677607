import React from "react";
import ReactGA from "react-ga";


/**
 * Google Analytics Hook to add Events.
 *
 * Can be used like:
 * const GAEventsTracker = usaGAEventsTracker("Button Clicks");
 * GAEventsTracker("Custom Action Name", "Custom Label Name");
 *
 * For instructions how to add it see: https://www.youtube.com/watch?v=MEsm6SUED4I
 *
 * @since 2021.10.14
 */
const useGAEventsTracker = (category = "Event Category") => {
  const trackEvent = (action = "action", label = "label") => {
    ReactGA.event({ category, action, label});
  };
  return trackEvent;
};

export default useGAEventsTracker;