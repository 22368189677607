import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import {Context} from './GlobalStates';

import Main from './pages/main/Main.js';
import Impressum from './pages/impressum/Impressum.js';


/**
 * Handles the routing part. We moved it outside, so that we can access globalStates.
 *
 * @since 2021.10.11
 */
export default function AppRoutes() {
  // GlobalStates
  const [globalStates, dispatch] = useContext(Context);

  console.log("AppRoutes was triggert");

  return (
    <>
      { (globalStates.appLoaded)
        ? (
            <Switch>
              <Route path="/impressum">
                { console.log("AppRoutes | Impressum") }
                <Impressum page="Impressum" />
              </Route>

              <Route path="/">
                <Main page="Main" />
              </Route>

              <Route path="*">
                <Main page="Main" />
              </Route>

              <Route>Byee</Route>
            </Switch>
          )
        : (<></>)
      }
    </>
  );
}