import {
  Box,
  CircularProgress
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import {Context} from './../../../GlobalStates';

import './../styles/LoginContentDialog.css';

import { ReactComponent as IconClose } from './../assets/Icon_X.svg';
import { ReactComponent as IconBack } from './../assets/Icon_Back.svg';

import { ValidateEmail } from './../helpers/Validation.js';

/**
 * Dialog for the globalStates.loginDialogArr: LoginContentDialog__PWReset__SendCode
 *
 * Expected globalStates.loginDialogArr[0] param:
 * @param dialog: "LoginContentDialog__PWReset__SendCode"
 * @param email: "email"               // can be empty
 * @since 2021.10.10
 */
function LoginContentDialog__PWReset__SendCode () {

  //GlobalStates
  const [globalStates, dispatch] = useContext(Context);

  const axios = require('axios').default;

  const [fetchApiData, setFetchApiData] = useState(null); // null|"rdy to start"|"finished"
  const [enteredEmail, setEnteredEmail] = useState(globalStates.loginDialogArr[0].email);

  // important, this LoginContentDialog__PWReset__SendCode.js file is only allowed to be included once, cuz we use here useEffect()
  useEffect(() => {
    async function fetchAPI(user_email) {
      console.log("fetchAPI | user_email[" + user_email + "]");
      const newPost = {
        request: "password_reset_send_code",
        user_email: user_email
      }

      let resp = ""
      try {
          resp = await axios.post(globalStates.url_api_account, newPost);
          //console.log(resp);
          //console.log("LoginContentDialog__PWReset__SendCode.js | API response | resp.response[" + resp.data.response + "]");
          switch(resp.data.response) {
            case "password_reset_send_code_success":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "The code has been send, please check your email (maybe also spam folder)." }});
              // see finally
              break;
            case "password_reset_send_code_wrong_email":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Sorry, I couldn't find that email yet. For registration please get in touch with the Marktplatzkomplizen." }});
              break;
            case "password_reset_send_code_technical_issue":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Technical issue, wasn't able to send the email. Please try again. If this error consists please let Marcel know." }});
              break;
            case "blocked_user":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Sorry to bother you but there is a technical issue with your email account. Please get in touch with Marcel to fix it." }});
              break;
            case "server_error":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Server error. If this error consists please let Marcel know." }});
              break;
            case "server_maintenance":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Server are currently under maintenance (this error should not happen). If this error consists please let Marcel know." }});
              break;
            default:
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Unexpected api response. If this error consists please let Marcel know." }});
          }
      } catch (err) {
          console.error(err);
          dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Unexpected error. If this error consists please let Marcel know." }});
      } finally {
          setFetchApiData("finished")
          if (resp.data.response === "password_reset_send_code_success") dispatch({type: 'LOGINDIALOGARR_ADD', payload: { dialog: "LoginContentDialog__PWReset__ConfirmCode", email: enteredEmail }}); // must be the last operation -> otherwise error
      }
    }

    if (fetchApiData === "rdy to start") {

      if (ValidateEmail(enteredEmail)) {

        // start getting new keywords -async approach
        // valid email & password syntax
        console.log("LoginContentDialog__Login.js | starting fetchApiData | enteredEmail[" + enteredEmail + "]");
        fetchAPI(enteredEmail);

      } else {
        setFetchApiData(null);
        dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Please check your email again. (Invalid syntax)" }});
      }

    } else if (fetchApiData === "finished") {
      // finished the new keyword search
    }

  }, [fetchApiData])


  const handleLoginOnKeyDownEmail = (event) => {
    setEnteredEmail(event.target.value.trim());
    if (event.key === 'Enter') {
      setFetchApiData("rdy to start") // starting getting the api data
    }
  }

  const handleOnClickHeaderBack = () => {
    dispatch({type: 'LOGINDIALOGARR_REMOVE_FIRST'});
  }

  const handleOnClickHeaderClose = () => {
    dispatch({type: 'LOGINDIALOGARR_REMOVE_ALL'});
  }

  const handleOnClickLoginBtn = (event) => {
    setFetchApiData("rdy to start") // starting getting the api data
  }

  return (
    <>
      <Box className="LoginContentDialog">
        <Box className="LoginContentDialog__Header">
          <IconBack className="LoginContentDialog__HeaderIconBack" fill="white" onClick={handleOnClickHeaderBack}/>
          <IconClose className="LoginContentDialog__HeaderIconClose" fill="white" onClick={handleOnClickHeaderClose}/>
          <span className="LoginContentDialog__HeaderText">Password Reset</span>
        </Box>
        <input autoComplete="off" type="email" name="emailInput" placeholder="Email..." value={enteredEmail} className="LoginContentDialog__Input" onChange={handleLoginOnKeyDownEmail} onKeyDown={handleLoginOnKeyDownEmail}/>
        <Box className="LoginContentDialog__Input LoginContentDialog__Btn" onClick={handleOnClickLoginBtn}>
        { (fetchApiData === "rdy to start")
          ? ( <CircularProgress className="LoginContentDialog__BtnSpinner"/> )
          : ( <>SEND CODE PER EMAIL</> )
        }
        </Box>
      </Box>
    </>
  )

};

export default LoginContentDialog__PWReset__SendCode;