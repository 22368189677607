import {
  Box
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import {Context} from './../../../GlobalStates';

import './../styles/LoginContentDialog.css';

import { ReactComponent as IconClose } from './../assets/Icon_X.svg';
import { ReactComponent as IconBack } from './../assets/Icon_Back.svg';

import { ValidateEmail } from './../helpers/Validation.js';
import { ValidatePassword } from './../helpers/Validation.js';

import usaGAEventsTracker from "./../../../hooks/useGAEventsTracker.js";

/**
 * Dialog for the globalStates.loginDialogArr: LoginContentDialog__Login
 *
 * Expected globalStates.loginDialogArr[0] param:
 * @param dialog: "LoginContentDialog__Login"
 * @param email: "email"               // can be empty
 * @since 2021.10.10
 */
function LoginContentDialog__Login () {

  //GlobalStates
  const [globalStates, dispatch] = useContext(Context);

  const axios = require('axios').default;

  const [fetchApiData, setFetchApiData] = useState(null); // null|"rdy to start"|"finished"
  const [enteredEmail, setEnteredEmail] = useState(globalStates.loginDialogArr[0].email);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const GAEventsTracker = usaGAEventsTracker("Button Clicks")

  // important, this LoginContentDialog__Login.js file is only allowed to be included once, cuz we use here useEffect()
  useEffect(() => {
    async function fetchAPI(user_email, user_password) {
      console.log("fetchAPI | user_email[" + user_email + "] user_password[" + user_password + "]");
      const newPost = {
        request: "login",
        user_email: user_email,
        user_password: user_password
      }

      let resp = "";
      try {
          resp = await axios.post(globalStates.url_api_account, newPost);
          console.log(resp);
          console.log("ToolSettings.js | API response | resp.response[" + resp.data.response + "]");
          switch(resp.data.response) {
            case "login_success":
              console.log("success");
              setEnteredEmail("");
              setEnteredPassword("");
              GAEventsTracker("Login Successfully", "Login Successfully [" + user_email.split('@')[0] + "]");
              // see finally
              // page switch will be automatically done via account logic
              break;
            case "login_wrong_email":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Sorry, I couldn't find that email yet. For registration please get in touch with the Marktplatzkomplizen from Hamburg." }});
              setEnteredPassword("");
              GAEventsTracker("Login Failure - Wrong Email", "Login Failure - Wrong Email [" + user_email.split('@')[0]  + "]");
              break;
            case "login_wrong_password":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Wrong password - please try again." }});
              setEnteredPassword("");
              GAEventsTracker("Login Failure - Wrong Password", "Login Failure - Wrong Password [" + user_email.split('@')[0]  + "]");
              break;
            case "login_password_retries_counter_exceeded":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Sorry for this additional work, but please reset your password." }});
              // added to finally to avoid missing on out on setFetchApiData("finished")
              break;
            case "login_password_reset_needed":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Hey there, let's set a new password please." }});
              // added to finally to avoid missing on out on setFetchApiData("finished")
              break;
            case "blocked_user":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Sorry to bother you but there is a technical issue with your email account. Please get in touch with Marcel to fix it." }});
              break;
            case "blocked_account":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Technical issue, please get in touch with Marcel." }});
              break;
            case "server_error":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Server error. If this error consists please let Marcel know." }});
              break;
            case "server_maintenance":
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Server are currently under maintenance (this error should not happen). If this error consists please let Marcel know." }});
              break;
            default:
              dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Unexpected api response. If this error consists please let Marcel know." }});
          }
      } catch (err) {
          console.error(err);
          dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Unexpected error. If this error consists please let Marcel know." }});
      } finally {
          setFetchApiData("finished");
          if (resp.data.response === "login_success") {
            dispatch({ type: 'SET_RUNAPPLOADINGSPINNER', payload: true });
            dispatch({ type: 'SET_REFRESHTOKEN', payload: resp.data.refresh_token }); // important to have this after the "finished" one to avoid speed racing
          } else if ((resp.data.response === "login_success") || (resp.data.response === "login_password_reset_needed")) {
            dispatch({type: 'LOGINDIALOGARR_ADD', payload: { dialog: "LoginContentDialog__PWReset__SendCode", email: user_email }});
          } else {
            GAEventsTracker("Login Failure", "Login Failure [" + user_email.split('@')[0] + "]");
          }
      }
    }

    if (fetchApiData === "rdy to start") {

      if (ValidateEmail(enteredEmail)) {
        if (ValidatePassword(enteredPassword)) {
          // start getting new keywords -async approach
          // valid email & password syntax
          console.log("LoginContentDialog__Login.js | starting fetchApiData | enteredEmail[" + enteredEmail + "] enteredPassword[" + enteredPassword + "]");
          fetchAPI(enteredEmail, enteredPassword);
        } else {
          setFetchApiData(null);
          if ((enteredPassword.length < 8) || (enteredPassword > 18)) dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "The password length needs to be between 8 and 18 characters." }});
            else dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Invalid password syntax. Allowed syntax: a-zA-Z0-9!@#$%^&*" }});
        }
      } else {
        setFetchApiData(null);
        dispatch({type: 'SET_NEW_SNACKBAR', payload: { status: "red", message: "Please check your email again. (Invalid syntax)" }});
      }

    } else if (fetchApiData === "finished") {
      // finished the new keyword search
      setFetchApiData(null);
    }

  }, [fetchApiData])


  const handleLoginOnKeyDownEmail = (event) => {
    setEnteredEmail(event.target.value.trim());
    if (event.key === 'Enter') {
      setFetchApiData("rdy to start") // starting getting the api data
    }
  }

  const handleLoginOnKeyDownPassword = (event) => {
    setEnteredPassword(event.target.value.trim());
    if (event.key === 'Enter') {
      setFetchApiData("rdy to start") // starting getting the api data
    }
  }

  const handleOnClickHeaderBack = () => {
    dispatch({type: 'LOGINDIALOGARR_REMOVE_FIRST'});
  }

  const handleOnClickHeaderClose = () => {
    dispatch({type: 'LOGINDIALOGARR_REMOVE_ALL'});
  }

  const handleOnChangeRememberMe = (event) => {
    setRememberMe(event.target.checked)
  }

  const handleOnClickForgotPassword = () => {
    dispatch({type: 'LOGINDIALOGARR_ADD', payload: { dialog: "LoginContentDialog__PWReset__SendCode", email: enteredEmail }});
    GAEventsTracker("Forgot Password Click", "Forgot Password Click [" + enteredEmail.split('@')[0]  + "]");
  }

  return (
    <>
      <Box className="LoginContentDialog">
        <Box className="LoginContentDialog__Header">
          <IconBack className="LoginContentDialog__HeaderIconBack" fill="white" onClick={handleOnClickHeaderBack}/>
          <IconClose className="LoginContentDialog__HeaderIconClose" fill="white" onClick={handleOnClickHeaderClose}/>
          <span className="LoginContentDialog__HeaderText">Login</span>
        </Box>
        <input autoComplete="off" type="email" name="emailInput" placeholder="Email..." value={enteredEmail} className="LoginContentDialog__Input" onChange={handleLoginOnKeyDownEmail} onKeyDown={handleLoginOnKeyDownEmail}/>
        <input autoComplete="off" type="password" name="passwordInput" placeholder={"Password..."} value={enteredPassword} className="LoginContentDialog__Input" onChange={handleLoginOnKeyDownPassword} onKeyDown={handleLoginOnKeyDownPassword}/>
        <Box className="LoginContentDialog__Footer">
          <Box className="LoginContentDialog__FooterRememberMe">
            <input className="LoginContentDialog__FooterRememberMeCheckbox" type="checkbox" id="rememberme" name="rememberme" checked={rememberMe} onChange={handleOnChangeRememberMe} />
            <span style={{ cursor: "context-menu" }}>Remember me</span>
          </Box>
          <span className="LoginContentDialog__FooterForgotPassword" onClick={handleOnClickForgotPassword}>Forgot password?</span>
        </Box>
      </Box>
    </>
  )

};

export default LoginContentDialog__Login;