import {
  makeStyles,
  Box,
  Container,
} from "@material-ui/core";

import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import Particles from "react-tsparticles";
import Data from "./assets/particlesmk.json";

import './styles/Impressum.css';


/** Throws an overlay, if the screensize is less than 1000x500px.
 *
 * @note mobile view is not supported, as it's an agency/work tool.
 * @since 2021.09.17
 */
function Impressum ({page}) {


  console.log("Impressum Page");

  useEffect(() => {
    document.title = "Impressum - WertUp"
  }, []); // just called once

  return (
    <Box className="Impressum">
      <Particles
        style={{ position: "absolute" }}
        height="100%"
        width="100%"
        params={Data}
      />
      <Box className="ImpressumContent">

        <text style={{ marginTop: 25 }}>
          <h1>Impressum</h1>
          Angaben gemäß § 5 TMG{"\n"}{"\n"}
          WertUp UG (haftungsbeschränkt){"\n"}
          Raum 31 0007{"\n"}
          Flutstraße 30{"\n"}
          47533 Kleve{"\n"}{"\n"}
          <strong>Vertreten durch: </strong>{"\n"}
          Geschäftsführer: Marcel Unger{"\n"}{"\n"}
          <strong>Kontakt:</strong>{"\n"}
          Telefon: 0176-55012325{"\n"}
          E-Mail: <a style={{color: "inherit"}} href='mailto:kontakt@wertup.com'>kontakt@wertup.com</a>{"\n"}{"\n"}
          <strong>Registereintrag: </strong>{"\n"}
          Eintragung im Registergericht: Kleve{"\n"}
          Registernummer: HRB 17556{"\n"}
          <strong>Umsatzsteuer-ID: </strong>{"\n"}
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE347140957.{"\n"}{"\n"}
          <strong>Wirtschafts-ID: </strong>{"\n"}
          Beantragt{"\n"}
          <strong>Aufsichtsbehörde:</strong>{"\n"}
          Amtsgericht Kleve{"\n"}{"\n"}

          <strong>Verbindung zu Amazon:</strong>{"\n"}
          WertUp UG (haftungsbeschränkt) ist ein unabhängiges Unternehmen und gehört weder vollständig noch teilweise zu Amazon.com oder einer Tochtergesellschaft von Amazon.com.
        </text>

      </Box>
    </Box>
  )

}

export default Impressum