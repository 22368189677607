import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, CssBaseline } from "@material-ui/core";

import {defaultTheme} from './styles/DefaultTheme.js';

import GlobalStates from './GlobalStates.js';

import AppRoutes from './AppRoutes.js';


/**
 *
 * Debug, start on localhost:3000 via: npm start
 *
 * Information towards uploading your app:
 * You can build the app for production via: npm run build
 * -> The finished code is inside the build folder
 *
 * New favicon:
 * 1. You need a transparent .svg
 * 2. Transform that .svg into an .png with transparent background
 * 3. Transform that .png into .ico  (.svg to .ico doesn't work with transparent background, need to use .png in the middle)
 *
 * ionos:
 * An upload to ionos is simple but to ensure the routing works properly you need to add an
 * .htaccess file with 'ErrorDocument 404 /index.html' inside, otherwise the Routing doesn't work.
 * That file is NOT automatically included inside the build folder after building the app and needs to be manually uploaded into the folder.
 * see -> https://www.ionos.com/digitalguide/websites/website-creation/what-does-the-404-not-found-error-mean/
 *
 * Benefit: can use directly the URL
 *
 * S3:
 * Just create an S3 bucket, under Properties "Static website hosting" -> enable.
 * Important here to also include index.html for the error page, otherwise the routing doesn't work.
 *
 * Contra: Ugly long url -> we can get around that with manually having like Route53 I guess (think so)
 *
 * Google Analytics:
 * We've added Google Analytics tracking. You can see it under Reports -> Real-time -> Content -> Last 30 min
 * As well as under event's for the btn clicks.
 * For instructions how to add it see: https://www.youtube.com/watch?v=MEsm6SUED4I
 * You can further see the results beside Real-time also under: Behaviour -> Events -> Overview, via Label you also see the emails
 *
 */


ReactDOM.render(
  <React.StrictMode>{  // todo I've got strict mode on, it might cause more render? - I have it in now just for safety reasons
    <GlobalStates>
      <HelmetProvider>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />

          <Router>
            {/*<AnalyticsLogger />*/}
            <AppRoutes />


          </Router>
       </ThemeProvider>
      </HelmetProvider>
    </GlobalStates>
  }</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
