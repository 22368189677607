import {
  Box
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import {Context} from './../../../GlobalStates';

import './../styles/LoginSnackbar.css';

import { ReactComponent as IconClose } from './../assets/Icon_X.svg';

/**
 * Snackbar implementation.
 *
 * A snackbar is a small notification which pops up at the bottom center of the screen. It allows us to display unexpected messages.
 * Primarly concerning network issues.
 *
 * It can only be one (the most recent) message be displayed.
 * The snackbar will disappear automatically after 5 seconds. If you need more seconds adjust: animation-delay: 3s;
 *
 * I wasn't able to create a smooth React implementation, so I use animation tricks + pure javascript, even though it's bad practice.
 * (React should have 100% control towards the rerendering, but I kind of interfere here)
 *
 * @important the Snackbar does not stack the messages and just displayed the most recent one.
 * @param toolStates.Snackbar // { status: "green", message: "It worked" }
 *                                 status: "green"|"orange"|"red" -> depending on how serious it is
 * @since 2021.10.05
 */
function LoginSnackbar () {

  //GlobalStates
  const [globalState, dispatch] = useContext(Context);

  useEffect(() => {
    //console.log("LoginSnackbar.js | useEffect |  toolStates.Snackbar[" + toolStates.Snackbar + "]");
    if (globalState.snackbar != null) {
      // display new snackbar
      // I wasn't able to get it to work with React rerender, so I do it hardcoded with JS -> bad practice
      var element = document.getElementById("LoginSnackbar");
      element.classList.remove("LoginSnackbar--FadeOutAnimation", "LoginSnackbar--Close");
      setTimeout(function(){
        element.classList.add("LoginSnackbar--FadeOutAnimation");
      }, 100); // small delay is needed, otherwise the DOM doesn't retrigger a new render
    }
  }, [globalState.snackbar]); // only fires if toolStates.Snackbar gets a new value

  const handleOnClickClose = () => {
      document.getElementById("LoginSnackbar").classList.add("LoginSnackbar--Close");
  }

  return (
    <>
      {(globalState.snackbar != null)
        ? <Box id ="LoginSnackbar" className="LoginSnackbar--shadow">
            <span className="LoginSnackbar__Message">{( globalState.snackbar != null) ? globalState.snackbar.message : "Msg"}</span>
            <Box className="LoginSnackbar__IconWrapper">
              <IconClose onClick={handleOnClickClose} style={{height: 30, paddingTop: 4, cursor: 'pointer'}} fill='#fff' />
            </Box>
          </Box>

        : <Box />
      }
    </>
  )

};

export default LoginSnackbar;