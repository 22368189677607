/** Helps validating code. (Needs to be checked on server side as well
 * It can be used via: (named export used)
 * import { ValidateEmail } from './Validation.js';
 * onClick={ () => {  ValidateEmail(email); }}
 *
 * 2 functions:
 * ValidateEmail,
 * ValidatePassword,
 * ValidatePasswordResetCode,
 *
 */

function ValidateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function ValidatePassword(password) {
  const re = /^[a-zA-Z0-9!@#$%^&*]{8,18}$/;
  return re.test(password);
}

function ValidatePasswordResetCode(code) {
  let isnum = /^\d+$/.test(code);
  return (isnum && (code.length == 6));
}

export {
  ValidateEmail,
  ValidatePassword,
  ValidatePasswordResetCode,
};