/** Global State which is accessable within whole app.
 * https://codeburst.io/global-state-with-react-hooks-and-context-api-87019cc4f2cf
 *
 * Following is needed to add within sub components to access the globalState:
 * import React, { useContext, useState } from "react";
 * import { Context } from './GlobalStates';
 * -----------------------------------------------------
 * And within the function:
 * //GlobalStates
 * const [globalStates, dispatch] = useContext(Context);
 * -----------------------------------------------------
 * From within the child, the globalState can be used like:
 * <Dialog fullScreen open={(globalState.openDialog === 'LOGIN')} ... -> only opens dialog is LOGIN is set
 * From within the child, the globalState can be changed like:
 * dispatch({type: 'SET_DIALOG', payload: 'LOGIN'}); -> means open login dialog
 * dispatch({type: 'DRAWER_OPEN', payload: ''}); -> means closing
 */
import React, {createContext, useReducer, useEffect, useContext, useState} from "react";

// helps to work with debounce (prevents an event overload -> e.g. via resize -> which would otherwise slow down UI massively/freeze)
import { debounce } from "lodash"; // https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js


/**
 * Global state variables which are accessable throughout the whole app.
 * They handle the main core logic.
 *
 * You can't pass objects within initializing states, but JSX elements!!!
 * -> Smart workaround with took me 3 days for the logo's to figure out.
 */
const initialGlobalStates =
    {
      appLoaded: true,  // set true, the moment account logic finished to check on everything once. ; needed to prevent early routing
      userIsAuthorized: false,
      runAppLoadingSpinner: true, // this should be only used for switching between pages [for between tools see runToolLoadingSpinner (here the left nav bar should stay visible)]
      //page: "",   // "Login"|"Dashboard" -> use location.pathname instead, return "/login"|"/dashboard"

      toolList: ['KeywordSearch'],
      activeTool: 'KeywordSearch',  // "KeywordSearch"|"UserManagement"
      runToolLoadingSpinner: false,  // can be implemented individual for each tool as the tool wants, should be invoked on each tool change as transit overlay

      // windows screen size (what you see incl scrollbars) [not page size]
      clientHeight: window.innerHeight,
      clientWidth: window.innerWidth,

      //logo_full
      //logo_square

      accessToken: "",   // String, allows access ; valid 1hr
      refreshToken: "",  // String, contains email & pw -> real login ; valid 7days

      clientName: 'String', // e.g. MK
      clientID: 'String', // prior accountID
      userEmail: 'String',
      userLanguage: 'String',
      userRole: "member",  // default value: member
      userToolList: 'String',

      snackbar: null, // null | { status: "green", message: "It worked" }

      // this should not be here though
      loginDialogArr: [/*{ dialog: "LoginContentDialog__PWReset__ChangePW", email: "nice@nice.de", code: "123456"}*/], // [{ dialog: "LoginContentDialog__Login", email: "nice@nice.de", password: "secret"}]   // [] or [{ dialog: "str", data...}, ...] ; all must have dialog attribute

      // AWS API Gateway/Lambda backend implementation for the account handling stuff
      url_api_account: "https://dhg9hyjkbb.execute-api.eu-central-1.amazonaws.com/production",

      // backend websocket communication
      webSocketMessages: [],   // array of objects, those objects will later be transformed into string. Must have timestamp attribute. e.g. { request: "ping", timestamp: 12345678, further request related payload...}
      webSocketResponses: []  // array containing all server responses
    };
const Context = createContext(initialGlobalStates);

/**
 * Reducer handels the changes of global state variables.
 * These case cases can be called throughout the whole application.
 */
const Reducer = (globalState, action) => {

    switch (action.type) {

        case 'SET_APPLOADED_TRUE': // should be only invoked once, after account logic finished
          console.log("GlobalStates.js | SET_APPLOADED_TRUE");
          return {
              ...globalState,
              appLoaded: true
          };

        case 'SET_USERISAUTHORIZED':
          console.log("GlobalStates.js | SET_USERISAUTHORIZED | action.payload: " + action.payload);
          return {
              ...globalState,
              userIsAuthorized: action.payload
          };

        case 'SET_RUNAPPLOADINGSPINNER':
          console.log("GlobalStates.js | SET_RUNAPPLOADINGSPINNER | action.payload: " + action.payload);
          return {
              ...globalState,
              runAppLoadingSpinner: action.payload // todo live on
          };

        case 'SET_CLIENTHEIGHT':
          //console.log("GlobalStates.js | SET_CLIENTHEIGHT | action.payload: " + action.payload);
          return {
              ...globalState,
              clientHeight: action.payload
          };

        case 'SET_CLIENTWIDTH':
          //console.log("GlobalStates.js | SET_CLIENTWIDTH | action.payload: " + action.payload);
          return {
              ...globalState,
              clientWidth: action.payload
          };

        case 'LOADED_LOGO_FULL':
            //console.log("LOADED_LOGO_FULL | reducer - globalState: ", globalState);
            return {
                ...globalState,
                logo_full_cached: true,
                globalStatesInitialized: (globalState.logo_full_pro_cached == true)
            };

        case 'LOADED_LOGO_FULL_PRO':
            //console.log("LOADED_LOGO_FULL_PRO | reducer - globalState: ", globalState);
            return {
                ...globalState,
                logo_full_pro_cached: true,
                globalStatesInitialized: (globalState.logo_full_cached == true)
            };

        // action.payload: { dialog: "str", data...}
        case 'LOGINDIALOGARR_ADD':
          //console.log("XXXXXXXXXXXXXGlobalStates.js | LOGINDIALOGARR_ADD | action.payload: " + JSON.stringify(action.payload));
          var newLoginDialogArr = [...globalState.loginDialogArr] // creates a real copy
          newLoginDialogArr.unshift(action.payload) // adds it to the front of the array, index pos 0 ; returns index pos, that's why can't use it directly
          return {
              ...globalState,
              loginDialogArr: newLoginDialogArr
          };

        case 'LOGINDIALOGARR_REMOVE_FIRST':
          //console.log("!!!!!!!!!!!!GlobalStates.js | LOGINDIALOGARR_REMOVE_FIRST | action.payload: " + action.payload);
          var newLoginDialogArr = [...globalState.loginDialogArr] // creates a real copy
          newLoginDialogArr.shift()  // removes the first element at index pos 0
          return {
              ...globalState,
              loginDialogArr: newLoginDialogArr
          };

        case 'LOGINDIALOGARR_REMOVE_ALL': // in case the user is pressing X
          //console.log("GlobalStates.js | LOGINDIALOGARR_REMOVE_ALL | action.payload: " + action.payload);
          return {
              ...globalState,
              loginDialogArr: []
          };

        // action.payload: [ { dialog: "str", data...} ]  ; must be an array
        case 'LOGINDIALOGARR_REPLACE': // only for the case that pw got changed successfully
          //console.log("GlobalStates.js | LOGINDIALOGARR_REPLACE | action.payload: " + action.payload);
          return {
              ...globalState,
              loginDialogArr: action.payload
          };

        case 'SET_NEW_SNACKBAR':
          //console.log("GlobalStates.js | SET_SNACKBAR | action.payload: " + action.payload);
          return {
              ...globalState,
              snackbar: action.payload
          };

        case 'SET_REFRESHTOKEN':
          //console.log("GlobalStates.js | SET_REFRESHTOKEN | action.payload: " + action.payload);
          return {
              ...globalState,
              refreshToken: action.payload
          };

        case 'SET_ACCESSTOKEN':
          //console.log("GlobalStates.js | SET_ACCESSTOKEN | action.payload: " + action.payload);
          return {
              ...globalState,
              accessToken: action.payload
          };

        case 'SET_USERROLE':
          //console.log("GlobalStates.js | SET_USERROLE | action.payload: " + action.payload);
          return {
              ...globalState,
              userRole: action.payload
          };

        case 'SET_ACTIVETOOL':
          console.log("GlobalStates.js | SET_ACTIVETOOL | action.payload: " + action.payload);
          return {
              ...globalState,
              activeTool: action.payload
          };

        case 'SET_RUNTOOLLOADINGSPINNER':
          console.log("GlobalStates.js | SET_ACTIVETOOL | action.payload: " + action.payload);
          return {
              ...globalState,
              runToolLoadingSpinner: action.payload
          };

        case 'SET_USEREMAIL':
          //console.log("GlobalStates.js | SET_USEREMAIL | action.payload: " + action.payload);
          return {
              ...globalState,
              userEmail: action.payload
          };

        case 'SET_OPENWEBSOCKET':
          console.log("GlobalStates.js | SET_OPENWEBSOCKET | action.payload: " + action.payload);
          return {
             ...globalState,
             openWebSocket: action.payload // true|false  default: false
          };

        // action.payload syntax: { request: "name", timestamp: timestamp, further request relayed payload}
        // the timestamp serves as unique ID, based on where we can remove the items later on -> avoid speed racing
        // timestamp is added here
        case 'WEBSOCKETMESSAGES_ADD_ONE':
          console.log("GlobalStates.js | WEBSOCKETMESSAGES_ADD_ONE | action.payload: " + action.payload);
          const newMsgObj = action.payload
          newMsgObj.timestamp = Date.now();
          console.log(JSON.stringify(newMsgObj));
          return {
             ...globalState,
             webSocketMessages: [...globalState.webSocketMessages, newMsgObj]
          };

        // action.payload contains of an array which contains all timestamps which related items shall be removed
        // e.g. action.payload: [13479027842,3487987943,4958ß049850]
        // we use the additional timestamp security layer to prevent any speed race conditions
        case 'WEBSOCKETMESSAGES_REMOVE_SOME':
          console.log("GlobalStates.js | WEBSOCKETMESSAGES_REMOVE_SOME | action.payload: " + action.payload);
          const timestampStr = action.payload.join(); // makes ["one","two","three"] to "one,two,three"
          const newWebSocketMessagesArr = globalState.webSocketMessages.filter(item => !item.timestamp.includes(timestampStr));
          console.log("GlobalStates.js | WEBSOCKETMESSAGES_REMOVE_SOME | webSocketMessages[" + JSON.stringify(globalState.webSocketMessages) + "] newWebSocketMessagesArr[" + newWebSocketMessagesArr + "]");
          return {
             ...globalState,
             webSocketMessages: newWebSocketMessagesArr
          };




        /*case 'SINGLE_LOGO_LOADED':
          console.log("action.name: " + action.name)
          return {
            ...globalState,
            imgLogoFull: action.payload,
          };*/

        /** Possible Dialogs to open:
         * 'LOGIN' -> Opens Login/Signup Dialog
         */
        case 'SET_DIALOG':
            //console.log("SET_DIALOG | action.payload: " + action.payload)
            return {
                ...globalState,
                openDialog: (action.payload) ? action.payload : '' // no payload means no disload to open
            };
        case 'DRAWER_OPEN':
          return {
              ...globalState,
              drawerOpen: true
          }
        case 'DRAWER_CLOSE':
          return {
              ...globalState,
              drawerOpen: false
          }

        default:
            console.log("GlobalStates.js | default (ERROR) | wrong case | action.payload: " + action.payload);
            return globalState;
    }
};


const GlobalStates = ({children}) => {
    // Singleton design pattern -> it only exist once -> initialGlobalStates
    const [globalStates, dispatch] = useReducer(Reducer, initialGlobalStates);

    // puts a hook on screensize changes for the screen height and width attribute we need to rely on further down
    // https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react


    // using debounce for UX improvement: https://stackoverflow.com/questions/61779470/cleaning-up-lodash-debounce-function-in-react-useeffect-hook
    const debounceDelay = 5; // only perform dispatch all 5ms after an event got triggered

    useEffect(() => {
      function handleResize() {
        //console.log("GlobalStates.js | debounce | window.innerHeight: " + window.innerHeight);
        dispatch({type: 'SET_CLIENTHEIGHT', payload: window.innerHeight});
      }
      const debounced = debounce(handleResize, debounceDelay);
      window.addEventListener('resize', debounced);
      return () => {
           //debounce.cancel()
           window.removeEventListener('resize', debounced);
       }
    }, [window.innerHeight]); // only fired once window.innerHeight changes in value

    useEffect(() => {
      function handleResize() {
        //console.log("GlobalStates.js | debounce | window.innerWidth:  " + window.innerWidth);
        dispatch({type: 'SET_CLIENTWIDTH', payload: window.innerWidth});
      }
      const debounced = debounce(handleResize, debounceDelay);
      window.addEventListener('resize', debounced);
      return () => {
           //debounce.cancel()
           window.removeEventListener('resize', debounced);
       }
    }, [window.innerWidth]); // only fired once window.innerHeight changes in value

    //console.log("GlobalStates.js");
    return (
      <Context.Provider value={[globalStates, dispatch]}>
          { /*dispatch({type: 'LOAD_LOGOS', payload: <Image name="Karsten"/>})*/ }
          {children}
      </Context.Provider>
    )
};


export {Context};
export default GlobalStates;
